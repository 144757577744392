<template>
    <div class="container-fluid">
        <Toolbar
            :click-add="showAdd"
            :click-edit="showEdit"
            :click-delete="showDelete"
            :click-refresh="showRefresh"
            :click-print="printStapel"
            :set-filter="loadDataSearch"
            :data   = "recs"
            :fields = "json_fields"
            type    = "xls"
            name    = "Stapel.xls"
        />
        <form class="m-3 row d-flex align-items-center" id="frmCust" v-on:submit.prevent="updateDat" autocomplete="off">
            <div class="col-md-2 pt-3">
                <p class="fw-bold">update data customer</p>
            </div>
            <div class="row col-md-3">
                <div class="input-group">
                    <input required type="text" disabled class="form-control" id="s_date" :placeholder="findObjFind('name','s_date')" v-model="dat.s_date">
                    <span class="input-group-text"><button v-on:click="showdate.s_date=true" type="button" class="btn " ><i class="fa fa-calendar"></i></button></span>
                    <transition name="calendar-fade">
                        <date-picker @close="showdate.s_date = false" v-if="showdate.s_date" v-model="dat.s_date"></date-picker>
                    </transition>

                </div>
            </div>
            <div class="col-md-4">
                <button form="frmCust" type="submit" class="btn btn-success">Update</button>
            </div>
        </form>
        <div class="row container-fluid m-0">
            <div class="col-md-4 p-0 mb-3">
                <div class="" id="boundary">
                    <table id="header" cellpadding="4" cellspacing="0" border="0" style="width:100%;">
                        <thead>
                            <tr class="w3-theme-l2">
                                <th width="50px">No. </th>
                                <th v-for="fl in filterByShow" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                <th width="1px"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-200) +'px;'">
                    <table class="w3-table w3-table-all">
                        <tr v-for="(dt, index) in recs" :key="dt.s_id" class="w3-hover-pale-blue w3-small cursor">
                            <td width="50px" class="w3-border-right">
                                <i :class="index == selectedRow ?'fa fa-caret-right':''"></i>&nbsp;{{dt.idx}}
                            </td>
                            <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','s_date')" class="w3-border-right w3-center">
                                {{dt.s_date}}
                            </td>
                            <td v-on:click="setKlikRow(index, dt)" :style="'width:'+findObjFind('width','s_keterangan')" class="w3-border-right">
                                {{dt.s_keterangan}}
                            </td>
                            <td :style="'width:'+findObjFind('width','s_keterangan')" class="w3-border-right">
                                <div style="position: relative;" :style="'width:' + findObjFind('width','s_keterangan')">
                                    <span v-html="setTextFind(dt.s_keterangan,'s_keterangan')"></span>
                                    <div v-show="index == selectedRow" style="position: absolute; top: -5px; right: 0px; text-align:center;" class="w3-opacity-off">
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="btn btn-success rounded-circle btn-sm" v-on:click="printStapel"><i class="fa fa-print w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-md-4 pe-0 scrlt-y" v-bind:style="'height:'+  (screenHeight-170) +'px;'">
                <div class="w3-theme" style="padding-left:5px;">
                    <b style="margin-right:10px;">Armada</b>
                    <button title="Add Armada" v-on:click="showAddArmada" class="w3-button w3-tiny w3-round-large" >
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
                <div class="">
                    <table cellpadding="6" cellspacing="0" border="0" style="width:100%;">
                        <thead>
                            <tr class="w3-theme-l2">
                                <th v-for="fl in filterByShowArmada" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                                <th width="1px"></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-240) +'px;'">
                    <table class="w3-table w3-table-all">
                        <tr v-for="(dtA, index) in recArmadas" :key="dtA.as_id" v-on:click="setKlikArmada(index, dtA)" class="w3-hover-pale-blue w3-small cursor">
                            <td :style="'width:'+findObjFindArmada('width','k_id')" class="w3-border-right w3-center" v-bind:class='{ "bg-danger text-white":dtA.as_stat == "F"}'>
                                <i :class="index == selectedArmada ?'fa fa-caret-right':''"></i>&nbsp;
                                {{dtA.k_name}}
                                <p class="fw-bold">{{dtA.as_ket}}</p>
                            </td>
                            <td :style="'width:'+findObjFindArmada('width','as_keterangan')" class="w3-border-right">
                                <div style="position: relative;" :style="'width:' + findObjFindArmada('width','as_keterangan')">
                                    <span v-html="setTextFind(dtA.as_keterangan,'as_keterangan')"></span>
                                    <div v-show="index == selectedArmada" style="position: absolute; top: -5px; right: 0px; text-align:center;" class="w3-opacity-off">
                                        <span v-on:click='Checks(dtA, index)' v-html="setStatus(dtA.as_stat)"></span>
                                        <span v-on:click='Pindaz(dtA, index)' v-html="setSiapkan(dtA.k_id)"></span>
                                        <button style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="EditArmada(dtA, index)"><i class="fa fa-pencil w3-large"></i></button>
                                        <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="DeleteArmada(dtA, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

        </div>
        <div class="col-md-4 pe-0 scrlt-y" v-bind:style="'height:'+  (screenHeight-170) +'px;'">
            <div class="w3-theme" >
                <b>Customer</b>
                <button title="Add Customer" v-on:click="showAddCust" class="w3-button w3-tiny w3-round-large" style="margin-left:10px;cursor:pointer;"><i class="fa fa-plus"></i></button>
            </div>
            <div class="">
                <table cellpadding="6" cellspacing="0" border="0" style="width:100%;">
                    <thead>
                        <tr class="w3-theme-l2">
                            <th v-for="fl in filterByShowCust" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                            <th width="1px"></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="scrlt-y mb-3" v-bind:style="'height:'+  (screenHeight-500) +'px;'">
                <table class="w3-table w3-table-all">
                    <tr v-for="(dtC, index) in recCusts" :key="dtC.cs_id" v-on:click="setKlikCust(index, dtC)" class="w3-hover-pale-blue w3-small cursor">
                        <td :style="'width:'+findObjFindCust('width','tc_id')" class="w3-border-right">
                            <i :class="index == selectedCust ?'fa fa-caret-right':''"></i>&nbsp;{{dtC.tc_name}}
                        </td>
                        <td :style="'width:'+findObjFindCust('width','cs_ppn')" class="w3-border-right w3-center">
                            {{dtC.cs_ppn}}
                        </td>
                        <td :style="'width:'+findObjFindCust('width','cs_keterangan')" class="w3-border-right w3-center">
                            <div style="position: relative;" :style="'width:' + findObjFindCust('width','cs_keterangan')">
                                <span v-html="setTextFind(dtC.cs_keterangan,'cs_keterangan')"></span>
                                <div v-show="index == selectedCust" style="position: absolute; top: -5px; right: 0px; text-align:center;" class="w3-opacity-off">
                                    <button style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-yellow" v-on:click="showPindah(dtC, index)"><i class="fa fa-share w3-large"></i></button>
                                    <button style="padding: 5px 7px 5px 7px; margin-right:1px;" class="w3-btn w3-circle w3-tiny w3-theme-d3" v-on:click="EditCust(dtC, index)"><i class="fa fa-pencil w3-large"></i></button>
                                    <button style="padding: 5px 7px 5px 7px; margin-right:5px;" class="w3-btn w3-circle w3-tiny w3-red" v-on:click="DeleteCust(dtC, index)"><i class="fa fa-trash-o w3-large"></i></button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="w3-theme" style="padding-top: 3px;padding-left:5px;height: 30px;">
                <span>
                    <b>Item</b>
                </span>
            </div>
            <div class="">
                <table cellpadding="6" cellspacing="0" border="0" style="width:100%;">
                    <thead>
                        <tr class="w3-theme-l2">
                            <th v-for="fl in filterByShowItem" :width="fl.width" :key="fl.name" class="">{{fl.name}}</th>
                            <th width="1px"></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="scrlt-y" v-bind:style="'height:'+  (screenHeight-440) +'px;'">
                <table class="table table-bordered">
                    <tr v-for="(dtI) in recItems" :key="dtI.ist_id" class="w3-hover-pale-blue w3-small cursor">
                        <td :style="'width:'+findObjFindItem('width','ic_id')" class="w3-border-right p-3">
                            {{dtI.ic_id}}
                        </td>
                        <td :style="'width:'+findObjFindItem('width','ist_qty')" class="w3-border-right w3-center">
                            {{dtI.ist_qty}}
                        </td>
                        <td :style="'width:'+findObjFindItem('width','g_id')" class="w3-border-right w3-center">
                            {{dtI.g_id}}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        </div>
        <!-- pagging -->
        <div >
            <nav class="Page navigation h9 bg-light bg-gradient shadow">
                <div class="d-flex justify-content-center">
                    <ul class="pagination text-center">
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="first()" href="#">First</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == 1 ?'dsb':''" class="page-link" @click="prev()" href="#">«</a></li>
                        <li class="page-item page-item active"><a class="page-link px-0 px-md-2" href="#">Page {{pages.halaman}} sd {{pages.ttlhalaman}}</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="next()" href="#">»</a></li>
                        <li class="page-item page-item active"><a :class="pages.halaman == pages.ttlhalaman ?'dsb':''" class="page-link" @click="last()" href="#">Last</a></li>
                        <li class="page-item page-item active"><a class="page-link  page-item active" href="#">Rec {{pages.ttlrec}}</a></li>
                        </ul>
                </div>
            </nav>
        </div>
        <!-- modal -->
        <div id="modal-form" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="w3-animate-zoom modal-content">
                <div class="modal-header pb-0">
                    <button  onclick="document.getElementById('modal-form').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                        <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Stafel</p>
                </div>
                <div class="modal-body">
                    <form class="w3-container" id="frmGdg" v-on:submit.prevent="saveData" autocomplete="off">
                        <!-- id stafel -->
                        <div class="w3-row" style="display:none;">
                            <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label for="s_id">{{findObjFind('name','s_id')}} :</label>
                            </div>
                            <div class="w3-col l4 m9 s12">
                                <input readonly type="text" class="w3-input w3-small w3-round-large" id="s_id" :placeholder="findObjFind('name','s_id')" v-model="rec.s_id">
                            </div>
                        </div>
                        <div class="mb-3">
                                <label for="s_date" class="form-label">
                                    {{findObjFind('name','s_date')}} :
                                </label>

                                <div class="input-group mb-3">
                                    <input required type="text" class="form-control" id="s_date" :placeholder="findObjFind('name','s_date')" v-model="rec.s_date">
                                    <span class="input-group-text"><button v-on:click="showdate.s_date=true" type="button" class="btn " ><i class="fa fa-calendar"></i></button></span>
                                    <transition name="calendar-fade">
                                        <date-picker @close="showdate.s_date = false" v-if="showdate.s_date" v-model="rec.s_date"></date-picker>
                                    </transition>

                                </div>
                        </div>
                        <div class="mb-3" style="margin-top:5px;">
                                <label class="form-label" for="s_keterangan">{{findObjFind('name','s_keterangan')}} :</label>
                                <input type="text" class="form-control" id="s_keterangan" :placeholder="findObjFind('name','s_keterangan')" v-model="rec.s_keterangan">
                        </div>
                        <div class="form-check mb-3">
                            <input v-model="rec.s_stat" type="checkbox" class="form-check-input" true-value="T" false-value="F">
                            <label class="container w3-small">{{findObjFind('name','s_stat')}}</label>
                        </div>
                    </form>
                    <footer class="modal-footer">
                        <div class="w3-right">
                        <button form="frmGdg" class="btn btn-success me-2" type="submit"><i class="fa fa-save"></i> Save</button>
                        <button type="button" class="btn btn-danger" onclick="document.getElementById('modal-form').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </div>
                    </footer>
                </div>

            </div>
        </div>

    </div>
        <!-- modal armada -->
        <div id="modal-armada" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-armada').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Tambah Armada</p>
                    </div>
                    <div class="modal-body">
                        <form class="w3-container" id="frmArm" v-on:submit.prevent="saveArmada" autocomplete="off">
                            <div class="mb-3" style="margin-top:5px;">
                                    <label for="as_id" class="form-label">{{findObjFindArmada('name','as_id')}} :</label>
                                    <input readonly type="text" class="form-control" id="as_id" :placeholder="findObjFindArmada('name','as_id')" v-model="recArmada.as_id">
                            </div>
                            <div class="mb-3" style="margin-top:5px;">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="k_id" class="form-label">{{findObjFindArmada('name','k_id')}} :</label>
                                        <model-list-select
                                        :list="kendaraans"
                                        class="form-select"
                                        option-value="k_id"
                                        option-text="k_name"
                                        v-model="recArmada.k_id"
                                        placeholder="Pilih Kendaraan"
                                    ></model-list-select>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="as_ket" class="form-label">{{findObjFindArmada('name','as_ket')}} :</label>
                                        <input type="text" class="form-control" id="as_ket" :placeholder="findObjFindArmada('name','as_ket')" v-model="recArmada.as_ket">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3" style="margin-top:5px;">
                                    <label for="as_keterangan" class="form-label">{{findObjFindArmada('name','as_keterangan')}} :</label>
                                    <input type="text" class="form-control" id="as_keterangan" :placeholder="findObjFindArmada('name','as_keterangan')" v-model="recArmada.as_keterangan">
                            </div>
                                <div class="mb-3">
                                        <input v-model="recArmada.as_stat" type="checkbox" class="form-check-input" true-value="T" false-value="F">
                                        <label class="form-check-label ms-2">{{findObjFindArmada('name','as_stat')}}</label>
                                </div>
                        </form>
                        <footer class="modal-footer">
                            <div class="w3-right">
                            <button form="frmArm" class="btn btn-success me-2" type="submit"><i class="fa fa-save"></i> Save</button>
                            <button type="button" class="btn btn-danger" onclick="document.getElementById('modal-armada').style.display='none'"><i class="fa fa-close"></i> Close</button>
                            </div>
                        </footer>
                    </div>

                </div>
            </div>

        </div>
        <!-- modal customer -->
        <div id="modal-customer" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-lg">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
                    <div class="modal-header pb-0">
                        <button @click.self="closeCust" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Tambah Customer</p>
                    </div>
                    <div class="modal-body">
                        <form id="frmCus" v-on:submit.prevent="saveCustomer" class="row">
                                    <input hidden type="text" class="w3-input w3-small w3-round-large" id="cs_id" :placeholder="findObjFindCust('name','cs_id')" v-model="recCust.cs_id">
                                <form id="frmSo" v-on:submit.prevent="cekSo" autocomplete="off">
                                    <div class="row col-12 mb-3" id="form-so">
                                    <label class="form-label" for="st_so">{{findObjFindBarang('name','st_so')}} :</label>
                                        <div class="col-md-10">
                                            <input type="text" class="form-control" required id="st_so" :placeholder="findObjFindBarang('name','st_so')" v-model="recItem.st_so">
                                        </div>
                                            <button form="frmSo" class="btn btn-success rounded col-md-2" type="submit">cek</button>
                                </div>
                                </form>
                                <div class="mb-3" style="margin-top:5px;">
                                        <label for="cs_id" class="form-label">{{findObjFindCust('name','cs_id')}} :</label>
                                        <input readonly type="text" class="form-control" id="cs_id" :placeholder="findObjFindCust('name','cs_id')" v-model="recCust.cs_id">
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-9" style="margin-top:5px;">
                                        <label for="tc_id" class="form-label">{{findObjFindCust('name','tc_id')}} :</label>
                                        <input type="text" hidden class="form-control" v-model="recCust.tc_id">
                                        <div v-for="(datas, index) in recc" :key="index" class="form-so" id="form-so">
                                            <input disabled type="text" class="form-control" id="cs_id" v-model="datas.cst_name">
                                        </div>
                                       <div id="form-cust">
                                        <input disabled type="text" class="form-control" id="cs_id" v-model="recCust.tc_name">
                                       </div>
                                </div>
                                <div class="col-md-3" style="margin-top:5px;">
                                        <label for="cs_ppn" class="form-label">{{findObjFindCust('name','cs_ppn')}} :</label>
                                        <input required type="text" class="form-control" id="cs_ppn" :placeholder="findObjFindCust('name','cs_ppn')" v-model="recCust.cs_ppn">
                                </div>
                                </div>
                                <div>
                                        <label for="cs_keterangan" class="form-label">{{findObjFindCust('name','cs_keterangan')}} :</label>
                                        <textarea type="text" class="form-control" id="cs_keterangan" :placeholder="findObjFindCust('name','cs_keterangan')" v-model="recCust.cs_keterangan"></textarea>
                                </div>
                                <div class="w3-row" style="margin-top:5px;">
                                    <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                        <label for="cs_stat">&nbsp;</label>
                                    </div>
                                </div>
                                <div class="w3-row">
                                    <table class="w3-table-all">
                                        <thead>
                                            <tr class="">
                                                <th class="w3-border-right">Item</th>
                                                <th class="w3-border-right w3-center" width="120px">Qty</th>
                                                <th class="w3-border-right w3-center" width="120px">Gudang</th>
                                                <th width="30px">
                                                    <button v-on:click="addItem" type="button" title="Tambah Item" class="w3-btn w3-tiny w3-blue w3-round-large" >
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="w3-small">
                                            <tr v-for="(dtIt, index) in recItems" :key="index">
                                                <td class="w3-border-right w-50">
                                                    <input type="text" class="form-control" v-model="dtIt.ic_id">
                                                </td>
                                                <td class="w3-border-right d-flex">
                                                    <input type="text" class="form-control me-2" v-model="dtIt.ist_qty">
                                                    <input type="text" class="form-control" v-model="dtIt.ist_keterangan">
                                                </td>
                                                <td class="w3-border-right">
                                                    <input type="text" class="form-control" v-model="dtIt.g_id">
                                                </td>
                                                <td>
                                                    <button v-on:click="removeItem(index, dtIt)" type="button" class="w3-btn w3-tiny w3-red w3-round-large">
                                                        <i class="fa fa-remove"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                    </form>
                        <footer class="modal-footer">
                            <div class="w3-right">
                            <button form="frmCus" class="btn btn-success me-2" type="submit"><i class="fa fa-save"></i> Save</button>
                            <button type="button" class="btn btn-danger"  @click.self="closeCust"><i class="fa fa-close"></i> Close</button>
                            </div>
                        </footer>
                    </div>

                </div>
            </div>

        </div>
        <!-- modal pindah -->
        <div id="modal-pindah" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-pindah').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Pindah Armada</p>
                    </div>
                    <div class="modal-body">
                        <form class="w3-container" id="frmPin" v-on:submit.prevent="EditPindah" autocomplete="off">
                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l4 m9 s12">
                                    <input hidden type="text" class="w3-input w3-small w3-round-large" id="cs_id" v-model="recCust.cs_id">
                                </div>
                            </div>
                            <div class="row" style="margin-top:5px;">
                                <div class="col-6">
                                    <label class="form-label">kendaraan saat ini :</label>
                                    <input readonly class="form-control" type="text" v-model="recArmada.k_name">
                                </div>
                                <div class="col-6">
                                    <label class="form-label">kendaraan tujuan :</label>
                                    <model-list-select
                                    tabindex="-1"
                                        :list="recArmadas"
                                        class="dropdown-menu zz"
                                        option-value="as_id"
                                        option-text="k_name"
                                        v-model="recPindas.as_id"
                                        placeholder="Pilih Kendaraan"
                                    ></model-list-select>
                                </div>
                            </div>
                        </form>
                        <footer class="modal-footer mt-4 pb-0">
                            <button form="frmPin" class="btn btn-success" type="submit"><i class="fa fa-save"></i> Save</button>
                            <button type="button" class="btn btn-danger" onclick="document.getElementById('modal-pindah').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </footer>
                    </div>

                </div>
            </div>

        </div>
        <!-- modal pindah hari-->
        <div id="modal-pindah-hari" class="modal row mt-4 top-50 start-50 translate-middle ms-md-0 ms-1 modal-md">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="w3-animate-zoom modal-content">
                    <div class="modal-header pb-0">
                        <button onclick="document.getElementById('modal-pindah-hari').style.display='none'" class="btn w3-display-topright btn-lg">&times;</button>
                            <p class="h9 fw-bold"><i class="mdi mdi-file-document-multiple-outline"></i>&nbsp; Pindah Tanggal</p>
                    </div>
                    <div class="modal-body">
                        <form class="w3-container" id="frmPins" v-on:submit.prevent="saveArmada" autocomplete="off">
                            <div class="w3-row" style="margin-top:5px;">
                                <div class="w3-col l4 m9 s12">
                                    <input hidden type="text" class="w3-input w3-small w3-round-large" id="cs_id" v-model="recCust.cs_id">
                                </div>
                            </div>
                            <div class="row" style="margin-top:5px;">
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Tanggal saat ini</label>
                                    <input readonly class="form-control" type="text" v-model="rec.s_keterangan">
                                </div>
                                <div class="col-md-12">
                                    <label class="form-label">Tanggal tujuan :</label>
                                    <model-list-select
                                    tabindex="-1"
                                        :list="recs"
                                        class="dropdown-menu"
                                        option-value="s_id"
                                        option-text="s_keterangan"
                                        v-model="recArmada.s_id"
                                        placeholder="Pilih Tanggal"
                                    ></model-list-select>
                                </div>
                            </div>
                        </form>
                        <footer class="modal-footer mt-4 pb-0">
                            <button form="frmPins" class="btn btn-success" type="submit"><i class="fa fa-save"></i> Save</button>
                            <button type="button" class="btn btn-danger" onclick="document.getElementById('modal-pindah-hari').style.display='none'"><i class="fa fa-close"></i> Close</button>
                        </footer>
                    </div>

                </div>
            </div>

        </div>
        <!-- Report -->
        <div id="cetakDetail" style="left:10px;top:75px;display:none;margin-left:50px;width:100%!important;">
            <!-- <header-report /> -->
            <div align="center">
                <h4>
                    <div><b>{{rec.s_date}}</b></div>
                </h4>
            </div>
            <table style="width:100%;border-collapse:collapse;" class="noborder">
                <thead>
                    <tr>
                        <th class="w3-border-black w3-border-left w3-border-right w3-border-top" width="227px">CUSTOMER</th>
                        <th class="w3-border-black w3-border-right w3-border-top" width="40px">J/Q</th>
                        <th class="w3-border-black w3-border-right w3-border-top" width="auto">ITEM</th>
                        <th class="w3-border-black w3-border-right w3-border-top" width="75px">QTY</th>
                        <th class="w3-border-black w3-border-right w3-border-top" width="77px">GUDANG</th>
                        <th class="w3-border-black w3-border-right w3-border-top" width="75px">ARMADA</th>
                    </tr>
                </thead>
            </table>
            <table style="width:100%;" class="detail">
                <tbody>
                    <tr v-for="(dtl, index) in recArmadas" :key="index">
                        <td width="auto">
                            <table style="width:100%;border-collapse:collapse;" class="noborder">
                                <tbody>
                                    <tr v-for="(dtlC, indexC) in dtl['cusST']" :key="indexC">
                                        <td width="225px" class="w3-border-top w3-border-right w3-border-black">
                                            <b>{{dtlC.tc_name}}</b><br/>
                                            {{dtlC.cs_keterangan}}
                                        </td>
                                        <td width="40px" class="w3-center w3-border-top w3-border-right w3-border-black">{{dtlC.cs_ppn}}</td>
                                        <td width="auto" class="w3-border-top w3-border-black">
                                            <table style="width:100%;border-collapse:collapse;" class="noborder">
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(dtlI, indexI) in dtlC['itmST']" :key="indexI">
                                                        <td width="10px">*</td>
                                                        <td width="auto" class="w3-border-right w3-border-black">{{dtlI.ic_id}}</td>
                                                        <td width="75px" class="w3-center w3-border-right w3-border-black">{{dtlI.ist_qty}} @{{dtlI.ist_keterangan}}</td>
                                                        <td width="75px" class="w3-center w3-border-black">{{dtlI.g_name}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr v-show="dtl.as_keterangan != ''">
                                        <td class="w3-center w3-border-top w3-border-black" colspan="3">{{dtl.as_keterangan}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td width="75px" class="">
                            {{dtl.k_name}}
                            <b>{{dtl.as_ket}}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Config from "@/conf/Config.js";
import axios from "axios";
import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";
import moment from "moment";

import Toolbar from "@/components/Toolbar.vue";
import DatePicker from "@/components/DatePicker.vue";

import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-buttons-bs5/js/buttons.bootstrap5"
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css"
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";


export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Stapel',
    components: {
        Toolbar,
        DatePicker,
        ModelListSelect
    },
    data() {
        return {
            ss_date : '2018-12-24 04:19:23',
            showdate :{s_date:false},
            screenHeight : 0,
            selectedRow : -1,
            selectedArmada : -1,
            selectedCust : -1,
            DatePickerFormat: 'YYYY-MM-DD',
            rec: {},
            recs: [],
            recArmada : {},
            recArmadas : [],
            recPindah : [],
            recPindas : {},
            recPindaz : {},
            recCust : {},
            recCusts : [],
            recItem :{},
            dat:{},
            recItems : [],
            reci : [],
            recc : [],
            obji : {},
            checkd : {},
            kendaraans: [],
            customers: [],
            items: [],
            gudangs: [],
            ikategori: [],
            pages : {halaman : 1, batas : 0, ttlhalaman : 0, ttlrec:0, limit:Config.perpage, search:''},
            filters : {
            	operator : 'AND',
                fields :[
                    {fieldFind : '', key : 's_id', type: 'text', name : 'ID', filter : true, width : '100px', grid : false},
                    {fieldFind : '', key : 's_date', type: 'text', name : 'Date', filter : true, width : '100px', grid : true},
                    {fieldFind : '', key : 's_keterangan', type: 'text', name : 'Keterangan', filter : true, width : 'auto', grid : true},
                    {fieldFind:'', key : 's_stat', type: 'select', name : 'Status', filter : true, width : '80px', grid : false, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                fieldsArmada :[
                    {fieldFind : '', key : 'as_id', type: 'text', name : 'ID', filter : true, width : '125px', grid : false,},
                    {fieldFind : '', key : 'k_id', type: 'text', name : 'Kendaraan', filter : true, width : '100px', grid : true, valueFind: [{key:'T',backgroundColor:'red'},{key:'F',backgroundColor:'none'}]},
                    {fieldFind : '', key : 'as_ket', type: 'text', name : 'keterangan waktu', filter : true, width : '100px', grid : false},
                    {fieldFind : '', key : 'as_keterangan', type: 'text', name : 'Keterangan', filter : true, width : 'auto', grid : true},
                    {fieldFind : '', key : 'as_stat', type: 'select', name : 'Status', filter : true, width : '80px', grid : false, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                fieldsCust :[
                    {fieldFind : '', key : 'cs_id', type: 'text', name : 'ID', filter : true, width : '125px', grid : false},
                    {fieldFind : '', key : 'as_id', type: 'text', name : 'Kendaraan', filter : true, width : '100px', grid : false},
                    {fieldFind : '', key : 'tc_id', type: 'text', name : 'Customer', filter : true, width : 'auto', grid : true},
                    {fieldFind : '', key : 'cs_ppn', type: 'text', name : 'PPN', filter : true, width : '50px', grid : true},
                    {fieldFind : '', key : 'cs_keterangan', type: 'text', name : 'Keterangan', filter : true, width : '150px', grid : true},
                    {fieldFind:'', key : 'cs_stat', type: 'select', name : 'Status', filter : true, width : '80px', grid : false, valueFind: [{key:'T',label:'Aktif'},{key:'F',label:'Tidak Aktif'}]},
                ],
                fieldsItem :[
                    {fieldFind : '', key : 'ist_id', type: 'text', name : 'ID', filter : true, width : '125px', grid : false},
                    {fieldFind : '', key : 'ic_id', type: 'text', name : 'Item', filter : true, width : 'auto', grid : true},
                    {fieldFind : '', key : 'ist_keterangan', type: 'text', name : 'Keterangan', filter : true, width : '125px', grid : false},
                    {fieldFind : '', key : 'ist_qty', type: 'text', name : 'Qty', filter : true, width : '100px', grid : true},
                    {fieldFind : '', key : 'g_id', type: 'text', name : 'Gudang', filter : true, width : '100px', grid : true},
                ],
                fieldsBarang :[
                    {fieldFind : '', key : 'st_so', type: 'text', name : 'No SO', filter : false, width : '125px', grid : true},
                ],
                find : []
            },
            /**Untuk transfer excel*/
            json_fields : {
                'ID' : 's_id',
                'TANGGAL' : 's_date',
                'KETERANGAN' : 's_keterangan'
            },
        }
    },
    methods : {
        findObjFind(key, val){
            return this.findObj(this.filters.fields,'key',val)[key];
        },
        findObjFindArmada(key, val){
            return this.findObj(this.filters.fieldsArmada,'key',val)[key];
        },
        findObjFindCust(key, val){
            return this.findObj(this.filters.fieldsCust,'key',val)[key];
        },
        findObjFindItem(key, val){
            return this.findObj(this.filters.fieldsItem,'key',val)[key];
        },
        findObjFindBarang(key, val){
            return this.findObj(this.filters.fieldsBarang,'key',val)[key];
        },
        findField(val){
            return this.findObj(this.fieldRules, 'field',val);
        },
        findObj(obj, key, value) {
            let ketemu = 0;
            for (let pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        setTextFind(txt,field){
            let fieldvalue = "";
            let ketemu = false;
            let self = this;
            if(self.filters.find!==undefined )
                if ( self.filters.find.length >0 ) {
                    self.filters.find.map(function(value){
                        if(value.fieldsArmada ===field){
                            ketemu = true;
                        }
                        if(fieldvalue!=='null')
                            fieldvalue += ((fieldvalue!=='')?';':'')+ value.fieldValue ;
                        else
                            fieldvalue += ((fieldvalue!=='null')?';':'')+ value.fieldValue ;
                    });
                }
            if(ketemu){
                return self.sethighlight(txt,fieldvalue);
            }else{
                return txt;
            }
        },
        first(){
            let self = this;
            self.pages.halaman = 1;
            self.loadData();
        },
        prev(){
            let self = this;
            self.pages.halaman = self.pages.halaman - 1;
            self.loadData();
        },
        next(){
            let self = this;
            self.pages.halaman = self.pages.halaman + 1;
            self.loadData();
        },
        last(){
            let self = this;
            self.pages.halaman = self.pages.ttlhalaman;
            self.loadData();
        },
        setKlikRow(index, dt){
            let self = this;
            self.selectedRow = index;
            dt['']
            // dt['s_date'] = moment(String(dt['s_date'])).format("DD-MM-YYYY"),
            self.rec = Object.assign({}, dt);
            self.loadArmadas(dt.s_id);
        },
        setKlikArmada(index, dt){
            let self = this;
            self.selectedArmada = index;
            self.recArmada = Object.assign({}, dt);
            self.loadCusts(dt.as_id);
        },
        setKlikCust(index, dt){
            let self = this;
            self.selectedCust = index;
            self.recCust = Object.assign({}, dt);
            self.loadItems(dt.cs_id);
        },
        loadData(){
            let self = this;
            self.screenHeight = window.innerHeight;
            self.selectedRow = -1;
            self.rec = {};
            let params = {
                pfunction:'show',
                limit: self.pages.limit,
                page: self.pages.halaman,
                search: self.pages.search
            }
            axios.post("master/ApiStapel.php",params).then(function (response) {
                let idx = (self.pages.limit*self.pages.halaman)-self.pages.limit+1;
                self.pages.ttlrec = parseFloat(response.data.ttlrec);
                self.pages.ttlhalaman = Math.ceil(parseFloat(response.data.ttlrec)/self.pages.limit);
                response.data.rec.map(function(val){
                    val['idx'] = idx;
                    idx++;
                });
                self.recs = response.data.rec;
            });
        },
        showRefresh(){
            this.pages.search = "";
            this.loadData();
        },
        loadDataSearch(filter){
            this.pages.search = filter;
            this.loadData();
        },
        async showAdd() {
            let self = this;
            self.stsAdd = true;

            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'STP',
                vc_table : 'tb_stapel'
            });
            self.rec = {
                s_id : key.data,
                s_date : moment(new Date()).format('YYYY-MM-DD'),
                s_keterangan : '',
                s_stat : 'T'
            };
            document.getElementById("modal-form").style.display = "block";

        },
        async showAddArmada(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih stapel.!');
                return false;
            }else{
                self.stsAdd = true;
                let key = await axios.post("admin/ApiGetId.php",{
                    pfunction : 'getId',
                    vc_prefix : 'ARM',
                    vc_table : 'tb_armadast'
                });
                self.recArmada = {
                    as_id : key.data,
                    s_id : self.rec.s_id,
                    k_id : '',
                    as_ket : '',
                    as_keterangan : '',
                    as_stat : 'F'
                };
                document.getElementById("modal-armada").style.display = "block";
            }
        },
        async showAddCust(){
            let self = this;
            if (self.selectedArmada < 0) {
                self.$toast.error('pilih armada.!');
                return false;
            }else{
                self.recc=[];
                    self.recItems=[];
                self.stsAdd = true;
                document.getElementById("form-so").style.display = "flex";
                document.getElementById("modal-customer").style.display = "block";
                document.getElementById("form-cust").style.display = "none";
            }
        },
        async addItem(){
            let self = this;
            let key = await axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'IST',
                vc_table : 'tb_itemst'
            });
            self.recItems.push({
                ist_id : key.data,
                cs_id : self.recCust.cs_id,
                ic_id : '',
                ist_qty : '',
                g_id : '',
                pfunction : 'add',
                userentry : localStorage.username
            });
        },
        showEdit(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih gudang.!');
                return false;
            }else{
                self.stsAdd = false;
                document.getElementById('modal-form').style.display='block';

            }
        },
        EditArmada(dt, index){
            let self = this;
            self.stsAdd = false;
            document.getElementById('modal-armada').style.display='block';
        },
        EditCust(dt, index){
            let self = this;
            self.stsAdd = false;
            document.getElementById('modal-customer').style.display='block';
            document.getElementById("form-so").style.display = "none";
            document.getElementById("form-cust").style.display = "block";
        },
        Checks(dt){
            let self = this;
            self.checkd = {
                as_id :dt.as_id,
                as_stat : 'T',
                pfunction : 'checks'
            }
            axios.post("master/ApiArmadaSt.php",self.checkd).then(function (response){
                if (response.data.success == true) {
                    self.$toast.success(response.data.msg);
                    self.loadArmadas(self.rec.s_id);
                } else {
                    self.$toast.error(response.data.msg);
                }
            });
        },
        showPindah(dt){
            let self = this;
            self.recPindas ={
                cs_id : dt.cs_id,
                as_id : '',
                pfunction : 'pindah'
            }
            document.getElementById('modal-pindah').style.display='block';
        },
        Pindaz(dt){
            let self = this;
            self.recArmada ={
                as_id : dt.as_id,
                s_id : '',
                as_stat : 'F'
            }
            document.getElementById('modal-pindah-hari').style.display='block';
        },
        EditPindah(){
            let self = this;
            axios.post("master/ApiCustomerSt.php",self.recPindas).then(function (response) {
                if (response.data.success == true) {
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-pindah').style.display='none';
                    self.recCusts=[];
                    self.recItems=[];

                } else {
                    self.$toast.error(response.data.msg);
                }
            });
        },
        EditPindaz(){
            let self = this;
            axios.post("master/ApiArmadaSt.php",self.recArmada).then(function (response) {
                if (response.data.success == true) {
                    self.$toast.success(response.data.msg);
                    document.getElementById('modal-pindah-hari').style.display='none';
                    self.recCusts=[];
                    self.recItems=[];

                } else {
                    self.$toast.error(response.data.msg);
                }
            });
        },
        updateDat(event){
            let self = this;
            self.dat = {
                pfunction : 'updatedat',
                s_date  : moment(String(self.dat['s_date'])).format("YYYY-MM-DD")
            }
            axios.post("master/ApiStapel.php",self.dat).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.dat = {};
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        saveData(event){
            let self = this;
            if (self.stsAdd == true) {
                self.rec.pfunction = "add";
            }else{
                self.rec.pfunction = "edit";
            }
            self.rec['userentry'] = localStorage.username;
            self.rec['s_date'] = moment(String(self.rec['s_date'])).format("YYYY-MM-DD");
            axios.post("master/ApiStapel.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    if (self.stsAdd) {
                        self.recs.splice(0, 0, self.rec);
                    }else{
                        self.recs[self.selectedRow] = self.rec;
                    }
                    document.getElementById('modal-form').style.display='none';
                    self.selectedRow = -1;
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        saveArmada(event){
            let self = this;
            if (self.stsAdd == true) {
                self.recArmada.pfunction = "adds";
            }else{
                self.recArmada.pfunction = "edits";
            }
            self.recArmada['userentry'] = localStorage.username;
            axios.post("master/ApiArmadaSt.php",self.recArmada).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.loadArmadas(self.rec.s_id);
                    document.getElementById('modal-armada').style.display='none';
                    document.getElementById('modal-pindah-hari').style.display='none';
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        async cekSo(){
            let self = this;
            axios.post("master/ApiBarangSt.php",{
                pfunction : 'getItem',
                st_so : self.recItem.st_so,

            }).then(function (response) {
                if(response.data.success == true){
                    self.recz = response.data.recz;
                    let keys = axios.post("admin/ApiGetId.php",{
                pfunction : 'getId',
                vc_prefix : 'ISS',
                vc_table : 'tb_itemst'
            });
                for (var i = 0; i < self.recz.length; i++) {
                    let v = self.recz[i];
                    self.recItems.push({
                        ist_id : key.data+[i],
                        cs_id : key.data,
                        ic_id : v.it_name,
                        ist_qty : v.it_qty,
                        ist_keterangan : v.it_uid,
                        g_id : v.it_g,
                        pfunction : 'add',
                        userentry : localStorage.username
            });
                    }
                }
            });
            let key = await axios.post("admin/ApiGetId.php",{
                    pfunction : 'getId',
                    vc_prefix : 'CSS',
                    vc_table : 'tb_csutomerst'
                });
            axios.post("master/ApiBarangSt.php",{
                pfunction : 'getCust',
                st_sos : self.recItem.st_so,
            }).then(function (response) {
                if(response.data.success == true){
                    self.recc = response.data.recc;
                let user = self.recc[0];
                self.recCust = {
                    cs_id : key.data,
                    as_id : self.recArmada.as_id,
                    tc_id : user.cust_id,
                    cs_ppn : user.tc_ppn,
                    cs_so : self.recItem.st_so,
                    cs_keterangan : user.cs_keterangan,
                    cs_stat : 'T'
                };
                }
            });

        },
       async closeCust(event){
        let self = this;
            self.recItems = [];
            self.recItem = {};
            self.recCust = {};
            self.recc = [];
            document.getElementById('modal-customer').style.display='none';

        },
        saveCustomer(event){
            let self = this;
            if (self.stsAdd == true) {
                self.recCust.pfunction = "add";
            }else{
                self.recCust.pfunction = "edit";
            }
            self.recCust['userentry'] = localStorage.username;
            axios.post("master/ApiCustomerSt.php",self.recCust).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.saveItems();
                    self.loadCusts(self.recArmada.as_id);
                    document.getElementById('modal-customer').style.display='none';
                    self.recc=[];
                    self.recItems=[];

                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        saveItems() {
            let self = this;
            self.recItems.map(function(key) {
                if(self.recCust.pfunction == "add"){
                    key.pfunction = "add"
                    axios.post("master/ApiItemSt.php",key).then(function (response) {
                    if(response.data.success == true){
                        console.log("Sukses", response.data.msg)
                    } else {
                        console.log("Gagal", response.data);
                    }
                });
                }else{
                    key.pfunction = "edit"
                    axios.post("master/ApiItemSt.php",key).then(function (response) {
                    if(response.data.success == true){
                        console.log("Sukses", response.data)
                    } else {
                        console.log("Gagal", response.data);
                    }
                });
                }
            });
        },
        showDelete() {
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih gudang.!');
                return false;
            }else{
                swal(
                    {
                        title: "Yakin Ingin Menghapus Data ..!",
                        text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#DD6B55",
                        confirmButtonText: "Ya, Hapus Data",
                        cancelButtonText: "Tidak, Batal Hapus",
                        closeOnConfirm: false,
                        closeOnCancel: true,
                    },
                    function(isConfirm) {
                        if (isConfirm) {
                            let vparams = {
                                s_id : self.rec.s_id,
                                pfunction : "delete"
                            };
                            axios.post("master/ApiStapel.php",vparams).then(function (response) {
                                if (response.data.success == true) {
                                    swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                                    self.$delete(self.recs, self.selectedRow);
                                }else{
                                    swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                }
                            });
                        }
                    }
                );
            }
        },
        DeleteArmada(dt, idx) {
            let self = this;
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        let vparams = {
                            as_id : dt.as_id,
                            pfunction : "delete"
                        };
                        axios.post("master/ApiArmadaSt.php",vparams).then(function (response) {
                            if (response.data.success == true) {
                                self.$delete(self.recArmadas,idx);
                                swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                            }else{
                                swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                            }
                        });
                    }
                }
            );
        },
        DeleteCust(dt, idx){
            let self = this;
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        let vparams = {
                            cs_id : dt.cs_id,
                            pfunction : "delete"
                        };
                        axios.post("master/ApiCustomerSt.php",vparams).then(function (response) {
                            if (response.data.success == true) {
                                self.$delete(self.recCusts,idx);
                                console.log(cs_id);
                                swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                            }else{
                                swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                                console.log(response.data);
                                console.log(dt.cs_id)
                            }
                        });
                    }
                }
            );
        },
        removeItem(idx, dt){
            let self = this;
            swal(
                {
                    title: "Yakin Ingin Menghapus Data ..!",
                    text: "Data Yang sudah di hapus tidak akan bisa dikembalikan",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Hapus Data",
                    cancelButtonText: "Tidak, Batal Hapus",
                    closeOnConfirm: false,
                    closeOnCancel: true,
                },
                function(isConfirm) {
                    if (isConfirm) {
                        let vparams = {
                            ist_id : dt.ist_id,
                            pfunction : "delete"
                        };
                        axios.post("master/ApiItemSt.php",vparams).then(function (response) {
                            if (response.data.success == true) {
                                self.$delete(self.recItems,idx);
                                swal({title: "Hapus..!",text: "Data Sudah terhapus",timer: 1000,type: "success"});
                            }else{
                                swal({title: "Delete",text: "Gagal delete data",type: "error",html: true});
                            }
                        });
                    }
                }
            );
        },

        loadArmadas(dt){
            let self = this;
            self.selectedArmada = -1;
            let params = {
                pfunction:'showbyid',
                s_id : dt,
            }
            axios.post("master/ApiArmadaSt.php",params).then(function (response) {
                self.recArmadas = response.data.rec;
            });
        },
        loadCusts(dt){
            let self = this;
            self.selectedCust = -1;
            let params = {
                pfunction:'showbyid',
                as_id : dt,
            }
            axios.post("master/ApiCustomerSt.php",params).then(function (response) {
                self.recCusts = response.data.rec;
            });
        },
        loadItems(dt){
            let self = this;
            let params = {
                pfunction:'showbyid',
                cs_id : dt,
            }
            axios.post("master/ApiItemSt.php",params).then(function (response) {
                self.recItems = response.data.rec;
            });
        },
        setStatus(dt) {
            if (dt === "F") {
                return "<button style='padding: 5px 7px 5px 7px; margin-right:5px;' class='w3-btn w3-circle w3-tiny w3-green'><i class='fa fa-check w3-large'></i></button>";
            }  else {
                return "";
            }
        },
        setSiapkan(dt) {
            if (dt === "MTR202203004"){
                return "<button style='padding: 5px 7px 5px 7px; margin-right:1px;'' class='w3-btn w3-circle w3-tiny w3-yellow'><i class='fa fa-share w3-large'></i></button>";
            } else {
                return "";
            }
        },
        setInit() {
            let self = this;
            axios.post("master/ApiKendaraan.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.kendaraans = response.data.rec;
            });
            axios.post("master/ApiCustomer.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
            axios.post("master/ApiCustomer.php",{
                pfunction : 'showk'
            }).then(function (response) {
                self.kendaraann = response.data.rec;
            });
            axios.post("master/ApiItem.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.items = response.data.rec;
            });
            axios.post("master/ApiGudang.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.gudangs = response.data.rec;
            });
            axios.post("master/ApiItemCategory.php",{
                pfunction : 'showall'
            }).then(function (response) {
                self.ikategori = response.data.rec;
            });
        },
        printStapel(){
            let self = this;
            if (self.selectedRow < 0) {
                self.$toast.error('pilih stapel.!');
                return false;
            }else{
                moment.locale('id')
                self.rec['s_date'] = moment(String(self.rec['s_date'])).format("LL"),
                self.recArmadas.map(function(val){
                    axios.post("master/ApiCustomerSt.php",{
                        pfunction:'showbyid',
                        as_id : val['as_id'],
                    }).then(function (recCus) {
                        val['cusST'] = recCus.data.rec;
                        recCus.data.rec.map(function(vl){
                            axios.post("master/ApiItemSt.php",{
                                pfunction:'showbyid',
                                cs_id : vl['cs_id'],
                            }).then(function (recItm) {
                                vl['itmST'] = recItm.data.rec;
                                self.$forceUpdate();
                                document.getElementById('idLoading').style.display='block';
                                setTimeout(() => {
                                    document.getElementById('idLoading').style.display='none';
                                    // document.getElementById('cetakDetail').style.display='block';
                                    let vhtml = document.getElementById('cetakDetail').innerHTML;
                                    if(print)
                                        self.printHtml(vhtml,'A4','portrait','5px 25px 5px 25px');
                                    else
                                        self.downloadFile('PO.xlsx',vhtml,'data:text/xls');
                                }, 1000);
                            });
                        });
                    });
                });
            }
        },
        printHtml(textHtml, kertas, bentuk, margin){
            let idprint = "de-print";
            let element = document.getElementById(idprint);
            if(element!=null)
                element.parentNode.removeChild(element);

            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', idprint);
            iframe.setAttribute('name', idprint);
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('display', 'none');
            iframe.setAttribute('width', 0);
            iframe.setAttribute('height', 0);

            document.body.appendChild(iframe);
            let newWin = window.frames[idprint];
            textHtml = `<!DOCTYPE html>
            <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <link rel="stylesheet" href="./assets/css/w3.css">
                    <link rel="stylesheet" href="./assets/css/report.css">
                    <title>Document</title>
                    <style>
                    .pindah-halaman {page-break-after: always;}
                    @media print {
                        @page {
                            size: ${kertas} ${bentuk};
                            margin : ${margin};
                        }
                    }
                    @font-face {
                        font-family: barcodefont;
                        src: url(assets/font-code/BarcodeFont.ttf);
                    }
                    .debarcode{
                        font-family: barcodefont;
                    }
                    </style>
                </head>
                <body onload="window.print()">
                    ${textHtml}
                </body>
            </html>`;
            newWin.document.write(textHtml);
            // console.log(textHtml);
            newWin.document.close();
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
        pNumber(num) {
            let x = num.replace(/\D/g, '').match(/(\d{0,4})(\d{0,4})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '' + x[3] : '');
        }
    },
    watch: {
        // 'moveCenter': function(){
        //     console.log("OK");
        // }
    },
    computed :{
        filterByShow(){
            return  this.filters.fields.filter(event => {
                return event.grid == true;
            });
        },
        filterByShowArmada(){
            return  this.filters.fieldsArmada.filter(event => {
                return event.grid == true;
            });
        },
        filterByShowCust(){
            return  this.filters.fieldsCust.filter(event => {
                return event.grid == true;
            });
        },
        filterByShowItem(){
            return  this.filters.fieldsItem.filter(event => {
                return event.grid == true;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
        this.loadData();
    }
};
</script>